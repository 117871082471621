<template>
  <div class="agreement wrapper">
    <p class="title-top" />
    <!--    eslint-disable vue/no-v-html-->
    <div
      class="div-partition"
      v-html="$t('agreement.NetboomServiceClauseqf', {project, email})"
    />
  </div>
</template>

<script>
export default {
  name: 'NetboomServiceClauseqf',
  metaInfo: {
    title: 'NETBOOM SERVICE'
  },
  data () {
    return {
      project: 'NETBOOM',
      email: 'service@netboom.com'
    }
  },
  created () {
    const flavor = this.$route.query.flavor || 'NETBOOM'
    this.project = flavor.toUpperCase()
    this.email = `service@${flavor.toLowerCase()}.com`
  }
}
</script>

<style lang="less">
  @import "~@/assets/styles/agreement.less";
</style>

<style lang="less" scoped>
  .wrapper{
    padding: 0 5%;
    background:#F0F0F0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
</style>
